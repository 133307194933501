import moment from 'moment/moment'
import Logo from '../assets/logo-light.png'

const Footer = () => {
  return (
    <footer className="w-full px-4 pb-6 flex flex-col justify-center items-center bg-primary-dark">
      <div className="px-10 py-6 flex items-center justify-center">
        <img src={Logo} alt="Schools Mutual Logo" className="w-36 md:w-48" />
      </div>
      <div className="text-center text-xs text-white max-w-lg">
        <p>
          © {moment().format('YYYY')} Schools Mutual is a trading name of SAS
          Mutual CIC (12499719) ICO License No: ZA757213
        </p>
        <p className="mt-2">
          A: Trigg House, 11 Maisies Way, South Normanton, DE55 2DS
        </p>
        <a
          href="//schoolsmutual.co.uk/privacy"
          target="_blank"
          rel="noreferrer"
          className="mt-2 block text-primary hover:text-white"
        >
          Privacy Notice
        </a>
      </div>
    </footer>
  )
}

export default Footer
